
import { toRem } from "@/utils/design"
import { computed, defineComponent, toRefs } from "vue"

export default defineComponent({
  props: {
    offsetTop: {
      type: Number,
      default: -30
    },
    content: {
      required: true,
      type: String
    }
  },
  setup(props) {
    const propsRef = toRefs(props)

    const tooltipStyle = computed(() => {
      return {
        top: toRem(propsRef.offsetTop.value)
      }
    })

    return {
      tooltipStyle
    }
  }
})
