
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    divided: {
      type: Boolean,
    },
  },
});
