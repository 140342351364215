
import { defineComponent } from "vue";
import AppleLogo from "./AppleLogo/index.vue";
import Datetime from "./Datetime/index.vue";
import Control from "./Control/index.vue";

export default defineComponent({
  components: { AppleLogo, Datetime, Control },
  setup() { }
});
