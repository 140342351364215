
import { defineComponent } from 'vue';
import MenuItem from '../MenuItem.vue';
import SystemIcon from '@/components/SystemIcon';
import TipBox from "@/components/TipBox/index.vue";
import ControlPanel from "./panel.vue";

export default defineComponent({
  components: { MenuItem, SystemIcon, TipBox, ControlPanel },
  setup() {
  }
});
