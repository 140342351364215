
import { toRem } from "@/utils/design";
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    top: {
      type: Number,
      default: 0
    },
    left: {
      type: Number,
      default: 0
    },
  },
  setup(props) {
    const isActive = ref(false)

    const onBoxClick = () => isActive.value = !isActive.value

    const tipStyle = computed(() => {
      return {
        left: toRem(props.left, true),
        top: 'calc(100% + ' + toRem(props.top, true) + ')'
      }
    })

    return {
      isActive,
      onBoxClick,
      tipStyle
    }
  }
});
