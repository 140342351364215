
import { computed, defineComponent, toRef, PropType } from "vue";
import { openApp } from '@/logic/app/openApp'
import { useDockStore } from "@/store/modules/dock";
import { OsApplication } from "@/application";

export default defineComponent({
  emits: [],
  props: {
    app: {
      required: true,
      type: Object as PropType<OsApplication>
    },
    size: {
      required: true,
      default: 50
    }
  },
  setup(props) {
    const iconSize = toRef(props, "size");
    const dockStore = useDockStore()

    const iconStyle = computed(() => ({
      width: iconSize.value + "px",
      height: iconSize.value + "px",
      top: "calc((3.5rem * " + iconSize.value / 50 + " - 3.5rem) / 2 * -1)"
    }));

    const isLoading = computed(() => {
      const loadingApps = dockStore.getLoadingAppids
      return loadingApps.has(props.app.id)
    })

    return { iconStyle, openApp, isLoading };
  }
});
