
import { defineComponent, PropType, ref } from "vue";
import {
  SystemMenu,
} from "@/components/SystemMenu";
import type { MenuItemProp } from "../SystemMenu/src/types";

export default defineComponent({
  components: { SystemMenu },
  props: {
    options: {
      type: Array as PropType<MenuItemProp[]>
    }
  },
  setup() {
    const isActive = ref(false);

    const onMenuClick = () => {
      isActive.value = !isActive.value;
    };

    return {
      isActive,
      onMenuClick,
    };
  }
});
