
import { defineComponent, onMounted, reactive, ref } from "vue";
import DockItem from "./item.vue";
import Tooltip from "@/components/Tooltip/index.vue";
import { useAppStore } from "@/store/modules/app";
import { useDockStore } from "@/store/modules/dock";

export default defineComponent({
  components: { DockItem, Tooltip },
  setup() {
    const defaultIconSize = 50;
    let dockOffsetLeft = ref(0);
    let dockRef = ref(null);
    const originalItemX = ref<number[]>([]);

    const dockStore = useDockStore()
    const applicationStore = useAppStore()

    const dockItemSize = reactive<number[]>(
      new Array(applicationStore.getApplications.length).fill(defaultIconSize)
    );

    const onDockMouseOver = (e: MouseEvent) => {
      let clientX = e.clientX;

      for (let i = 0; i < dockItemSize.length; i++) {
        const a = clientX - (i * 50 + dockOffsetLeft.value);
        const b = 50;
        let iScale = 1 - Math.sqrt(a * a + b * b) / 300;

        if (iScale < 0.5) {
          iScale = 0.5;
        }

        dockItemSize[i] = 100 * iScale;
      }
    };

    const onDockMouseOut = () => dockItemSize.fill(defaultIconSize);

    onMounted(() => {
      dockOffsetLeft.value =
        ((dockRef.value as unknown) as HTMLElement).offsetLeft + 8;

      for (let i = 0; i < dockItemSize.length; i++) {
        originalItemX.value.push(50 * i);
      }
    });

    return {
      onDockMouseOver,
      onDockMouseOut,
      originalItemX,
      dockRef,
      dockItemSize,
      dockStore,
      applicationStore
    };
  }
});
