<template>
  <div class="menu-line"></div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style lang='scss' scoped>
.menu-line {
  width: 100%;
  height: 0.0625rem;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  margin: 0.25rem auto;
  background-color: rgb(132 132 132 / 55%);
  border-radius: 5rem;
}
</style>